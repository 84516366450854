<template>
  <div id="container">
    <div v-if="fromResend" class="fromResend">
      <h2>Un nuovo codice di attivazione è stato spedito alla tua email.</h2>
    </div>
    <div v-if="step == 0">
      <div v-if="!fromResend">
        <h2>{{ $gettext('Grazie per esserti registrato.') }}</h2>
        {{ $gettext('Ti è arrivato via email un codice di conferma.') }}
      </div>
      <div>
        {{ $gettext('Inseriscilo qui sotto per completare la tua registazione.') }}
        <br />
      </div>
      <div id="form">
        <form @submit.prevent="send">
          <div id="code_div">
            <custom-input
              label="codice"
              :value="codice"
              type="text"
              @input="(val) => (codice = val)"
            />
          </div>
          <div id="button">
            <button :disabled="!fillOk || loading">{{ $gettext('Invia')}}</button>
          </div>
        </form>
      </div>
      <div id="loading" v-if="loading">{{ $gettext('Controllo codice in corso...') }}</div>
      <div id="errorForm" v-if="error != ''" v-html="error" class="error"></div>
    </div>
    <div v-if="step == 1">
      {{ $gettext('La registrazione è completa. Ora puoi effettuare il login all\'interno della app.') }}
      <br />
      <br />
      <div class="buttons">
        <button class="buttons" @click="login">{{ $gettext('Effettua il login') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
export default {
  name: 'signup_code',
  components: { customInput },
  data() {
    return {
      step: 0,
      codice: '',
      error: '',
      loading: false,
      fromResend: false,
    };
  },
  methods: {
    send() {
      this.error = '';
      this.loading = true;
      let regex = new RegExp(/[^a-zA-Z0-9]/gi);
      const codiceCheck = this.codice.replace(regex, '');
      if (this.codice != codiceCheck || this.codice.length != 6) {
        this.error = $gettext('Il codice inserito non è valido');
        this.loading = false;
        return;
      }

      this.axios
        .post(this.api.checkcode, { code: this.codice.trim() })
        .then((response) => {
          this.loading = false;
          this.step = 1;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response.response.data.message);
          this.error = response.response.data.message;
        });
    },
    login() {
      this.$router.push('login');
    },
  },
  computed: {
    fillOk() {
      return this.codice.trim() != '' ? true : false;
    },
  },
  mounted() {
    if (typeof this.$route.params.from != 'undefined') {
      this.fromResend = true;
    }
  },
};
</script>

<style scoped>
.fromResend h3 {
  font-weight: 600;
}

.error {
  font-size: 150%;
  color: var(--var-primary-color);
  font-weight: bold;
}

#container {
  padding: 0 30px 0 30px;
}
</style>
